
/**
 * ---------------------------------------------------------------------------
 * 0?. Work Section Styles
 * ---------------------------------------------------------------------------
 */

 	.rex-filter-btns{
 		// padding-top: 30px;
 		padding-top: 15px;
 	}
	.rex-featured{
		padding: 30px 0 13px;
		img{
			width: 100%;
		}
	}
	.rex-featured,
	.rex-featured-img{
		.display-flax{
			display: block;
		}
	}

	.featured-box > .col-md-6{
		padding: 0;
	}

	.rex-featured-description{
		// padding: 7px 0;
		padding: 7px 15px;
		// border: 1px solid $border-color;
		h6{
			margin-bottom: 0;
		    font-size: 20px;
		    font-weight: 400;
		    line-height: 30px;
		}
		span{
			color: $brand-primary;
			padding-bottom: 10px;
		}
		.col-md-6{
			padding: 0px;
		}
	}

	.rex-featured-img{
		position: relative;
		img{
			margin: 5px 0;
			&:hover{
				border-color: $brand-primary;
			}
		}
	}

	.rex-featured-img-list{
		position: relative;
		transition: 0.5s;
	    margin-bottom: 10px;
		&:before{
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background: $brand-primary;
			z-index: 5;
			transform: scale(0.5);
			transition: 0.5s;
			opacity: 0;
		}
	    &:hover{
	    	transform: translateY(-5px);
	    	box-shadow: 0 3px 12px 0px rgba(0, 0, 0, 0.38);
		    img{
		    	transform: scale(1.2);
		    }
		    &:before{
		    	transform: scale(1);
		    	opacity: 0.8;
		    }
		    .caption{
		    	opacity: 1;
	    		transform: scale(1) translate(-50%, -50%);
		    }
	    }
	}

	.work-img{
	    overflow: hidden;
	    transition: 0.4s;
	    img{
	    	width: 100%;
	    }
	}

	.caption{
		position: absolute;
		top: 50%;
		left: 50%;
		text-align: center;
		z-index: 10;
		opacity: 0;
		transition: 0.5s;
		transform: scale(0.4) translate(-50%, -50%);

		h4,h6{
			color: #fff;
		}
	}






/**
 * ----------------------------------------------------------------------------------------
 * 07. Work Section (Clients and Portfolio Showcase)
 * ----------------------------------------------------------------------------------------
 */

.portfolio-section{
	background: #fff;
}

.rex-portfolio-showcase{
	h3{
		margin-top: 0;
	}
	/*
	 * Portfolio Modal (Pop-up) Style
	 */
	.rex-modal {
		position: absolute;
		top: 50%;
		left: 50%;
		height: auto;
		z-index: 2000;
		visibility: hidden;
		backface-visibility: hidden;
		transform: translateX(-50%) translateY(-50%);
		background-color: #fff;
    width: 90%;
    max-width: 960px;

		.rex-content{
			position: relative;
			img{
				padding-left: 40px;
			}
		}

		button.rex-close-modal{
			position: absolute;
			right: 0;
			top: 0;
			border: none;
			outline: none;
			background-color: rgba(0,0,0,.1);
			color: #fff;
			transition: all 0.2s;
			padding: 5px 10px;

			&:hover{
				color: $text-color;
				background-color: rgba(0,0,0,.2);

			}
		}
		hr{
			margin-bottom: 15px !important;
		}

		img{
			// margin-top: 40px;
			// padding-bottom: 40px !important;
			padding-left: 0 !important;
			padding-right: 0;
			max-height: 370px;
			width: 100%;
		}

		figcaption{
			padding: 40px 30px;
			background-color: #fff;
			hr{
				margin: 0;
			}
			h3, h4, p{
				text-align: left;
			}
			h3{
				margin-bottom: 10px;
				margin-top: 0;
				color: $text-color;
			}
			h4{
				margin-top: 0;
				margin-bottom: 15px;
				color: $text-color;
			}
			p{
				line-height: 2;
				margin: 0;
			}
		}
	}

	.rex-show {
		visibility: visible;
	}

	.rex-overlay {
		position: fixed;
		width: 100%;
		height: 100%;
		visibility: hidden;
		top: 0 !important;
		left: 0;
		z-index: 1000;
		opacity: 0;
		background: rgba(0,0,0,0.8);
		transition: all 0.3s;
	}

	.rex-show ~ .rex-overlay {
		opacity: 1;
		visibility: visible;
	}


	/*
	 * Portfolio Showcase Style
	 */
	div.rex-portfolio-showcase{

		/* Portfolio Figure */

		h4.price{
			margin-top: 30px;
			color: red;
		}

	}
}

.rex-works-showcase div.rex-portfolio-showcase div.rex-portfolios figcaption {
  position: absolute;
  top: 20%;
  left: 20%;
  right: 20%;
  bottom: 20%;
  opacity: 0;
  // width: 100%;
  // height: 100%;
  margin-right: 10px;
  cursor: pointer;
  animation-duration: .5s;
  background-color: rgba(77, 191, 217, 0.9);;
  text-align: center;
}
.rex-works-showcase div.rex-portfolio-showcase div.rex-portfolios figcaption hr, .rex-works-showcase div.rex-portfolio-showcase div.rex-portfolios figcaption p {
  display: none;
}

.rex-featured,
.rex-featured-img{
    .display-flax{
        border: 1px solid #e8e8e8;
    }
}

/*-----------------------------------------------*/
.portfolio-section{
	// padding: 40px;
	padding: 22px 40px;
}

.rex-portfolio-item{
	transition: 0.5s;
	margin: 10px 0;
	.animated{
		transition: 0.5s;
	}
	&:hover{
		.work-img{
		    overflow: hidden;
		    img{
			    transform: scale(1.2);
		    }
		}
		.animated{
			top: 0 !important;
			left: 15px !important;
			right: 5px !important;
			bottom: 0 !important;
			opacity: 1 !important;
			.rex-portfolios-title{
				transform: translate(-50%, -50%);
				h6{
					transition: 0.5s;
				}
			}
		}
	}
}

.dom-featured{
    border: 1px solid $border-color;
    margin-bottom: 15px;
    margin-top: 30px;
	margin-left: 0;
	margin-right: 0;
	.work-img{
		display: inline;
		img{
			margin-right: 15px;
			padding: 0;
			// width: 50%;
			width: 100%;
		}
	}
	.rex-featured-description,.animated{
		padding-right: 15px;
		display: block !important;
		padding-left: 15px;
	    padding-top: 30px;
		.rex-portfolios-title{
			position: static;
			h6{
				color: $text-color;
			}
			span{
				color: $brand-primary;
			}
		}
	}
}

.rex-portfolios-title{
	position: absolute;
	left: 50%;
	top: 50%;
	transition: 0.5s;
	// transform: translate(-50%, -50%);
	min-width: 140px;
	h6{
		font-size: 20px;
		font-weight: 400;
		transition: 0.5s;
		margin: 0;
		color: $white-base;
	}
	span{
		color: $white-base;
	}
}

.rex-show{
	.rex-portfolios-title{
		position: static;
		h6{
			color: $text-color;
		}
		span{
			color: $brand-primary;
		}
	}
}
