/*	Button style 
------------------------*/


.rex-primary-btn{
	font-size: 18px;
	color: $white-base;
	border-color: transparent;
	background: $brand-primary;
	overflow: hidden;
	font-weight: 300;
    min-width: 160px;
    border-radius: 2px;
	@include padding(17px 28px);
	@include transition(0.5s);
	&:hover{
		color: $white-base;
		background: ($brand-primary - 15%);
	    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0,0,0,0.15);
	}
	span{
		font-weight: 300;
	}
}

.rex-primary-btn-effect{
	@extend .rex-primary-btn;
	@extend .square;
	&:hover{
		color: $white-base;
		background: ($brand-primary - 15%);
		&:before{
			right: 0;
		}
		&:after{
			top: 0;
		}
	}
}

.rex-btn-background-effect{
	@extend .rex-primary-btn;
	@extend .square;
	&:before{
		height: 100%;
	}
	&:hover{
		color: $white-base;
		&:before{
			width: 90%;
			height: 100%;
			right: 0;
			top: 0;
			background: ($brand-primary - 30%);
		}
	}
	span{
		position: relative;
	}
}

.rex-btn-Nobackground-effect{
	@extend .rex-btn-background-effect;
	background: none;
}

.rex-primary-btn-No{
	@extend .rex-primary-btn;
	background: none;
	border-color: $white-base;
    min-width: 160px;
    border-radius: 2px;
}

.rex-primary-btn-effect-No{
	@extend .rex-primary-btn-No;
	@extend .rex-primary-btn-effect;
}

.rex-btn-background-effect-No{
	@extend .rex-primary-btn-No;
	@extend .rex-btn-background-effect;
	&:hover{
		background: rgba(62, 176, 202, 0.51);
		border-color: $brand-primary;
		&:before{
			background: rgba(47, 161, 187, 0.28);
		}
	}
}


.dark-color{
	border-color: #404040;
	color: #404040;
}

