

/*============================
*** Experience style
==============================*/

.experience{
	padding: 24px;
	.listing-content{
		// margin-top: 20px;
		// padding-top: 20px;
		margin-top: 0;
		padding-top: 0;
		margin-bottom: 33px;
		transition: 0.4s;
		&:last-child{
		    margin-bottom: 3px;
		}
		&:hover{
			transform: translateY(-5px);
			.angle{
				border-color: lighten($btn-primary-bg, 10%) transparent transparent transparent;
			}
		}
	}
	.list-img,
	.list-description{
		border: transparent;
	}
	.list-description{
		@include border-width(1px 1px);
		@include border-style(solid);
		border-color: $border-color;;
		transition: 0.4s;
	}
	.list-description{
		padding: 1% 5% 0;
		h6{
			font-size: 17px;
			transition: 0.4s;
		}
		p{
			transition: 0.4s;
		}
		span{
			font-size: 14px;
			transition: 0.4s;
		}
	}
	.angle{
		position: absolute;
		bottom: 0;
		right: 0;
		display: none;
		transition: 0.4s;
	}
	.list-img{
		z-index: 1;
		padding: 0;
	}
}

.experience-date{
	width: 100%;
	// height: 120px;
    z-index: 1;
	background: ($rex-border-lighter - (-27%));
	text-align: center;
	transition: 0.4s;
    // transform: translate(0, -17px);
	h6{
		margin: 0;
		// padding: 30% 0;
		padding: 6% 0;
	    font-size: 15px;
	    letter-spacing: 0.8px;
	    transition: 0.4s;
	}
}

