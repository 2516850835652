

/*============================
*** Education style
==============================*/

.education{
	padding: 22px;
	h6,p,span,.list-img{
		transition: 0.4s;
	}
	.list-description {
		h6{
			font-size: 17px;
		}
	}
	.listing-content{
		transition:0.4s;
	    border: 1px solid #F1EFF0;
	        margin-bottom: 10px;
		&:hover{
		    padding-bottom: 1px;
		    transform: translateY(-5px);
		}
	}
	.list-img{
	    background: #F1EFF0;
	}
}

.listing-content{
	display: block;
}

.list-img,
.list-description{
	@extend .border;
	border: 0;
}


.list-img{
	padding: 9px;
}

.list-description{
	h6{
		margin-bottom: 0;
	}
	span{
		padding-bottom: 10px;
		color: $brand-primary;
	}
}
