/**
 * ---------------------------------------------------------------------------
 * 0?. Responsive Styles
 * ---------------------------------------------------------------------------
 */

    /* Custom, iPhone Retina */
    @media only screen and (min-width : 320px){}

        /* Extra Small Devices, Phones */
    @media only screen and (min-width : 480px){

        .sidebar{
            @include padding(42px null 45px null);
        }
        // header three
        .header-three{
            .top-title{
                font-size: 26px;
            }
            .author-name{
                font-size: 60px;
            }
        }


        .rex-filter-btns{
            a{
                display: inline-block;
            }
        }

    	.about-section,.section-block,.single-blog-list{
    		@include padding(40px);
    	}

        .reference-title{
            padding: 2.5% 0;
        }

        // skillbar-bar
        .section-block.skill-section{
            h6{
                margin-top: 0;
            }
        }

        .skillbar{
            margin-bottom: 0;
        }

        .skillbar-bar{
            transform: translateY(-5px);
        }
        .skill-bar-percent{
            display: inline-block;
            transform: translate(-23px, -5px);
            box-shadow: 0px 1px 0.5px 1px rgba(208,208,208,0.5);
            position: relative;
            top: 0;
            background: #fff;
        }

        .rex-filter-btns .btn{
            display: inline-block;
            min-width: 104px;
            margin-right: 4px;
            margin-bottom: 4px;
        }

        .rex-portfolio-showcase {
            padding: 0 26px;
        }
    }

        /* Small Devices, Tablets */
    @media only screen and (min-width : 768px){
        
        .nav-down  #rex-sticky,
        .nav-up  #rex-sticky{
            // transition: 0.5s;
            nav{
                // box-shadow: none !important;
            }
        }

        // #rex-sticky{
        //     background: $brand-primary;
        // }

        .nav-up{
            #rex-sticky{
                margin-top: -60px;
                transition: 0.5s;
                background: $brand-primary;
            }
        }
        .nav-down{
            #rex-sticky{
                margin-top: 0;
                transition: 0.5s;
                background: transparent;
            }
        }

        .nav-down > .is-sticky{
            #rex-sticky{
                background: $brand-primary;
                box-shadow: 0 3px 12px 0px rgba(0, 0, 0, 0.38);
            }
            .navigation{
                box-shadow: none !important;
                ul > li{
                    background: $brand-primary;
                }
                ul li ul li{
                    background: $brand-primary;
                }
            }
        }



        // about-section,.section-block
    	.about-section,.section-block,.single-blog-list{
    		@include padding(28px 68px 28px 68px);
    	}

        // .hero
        .hero{
            .hero-btn > a{
                margin: 0 15px;
            }
            .hero-inner{
                padding: 4.5em 0;
                .media-link{
                    padding: 30px 0;
                }
            }
        }

        .contact-btn > a{
            margin: 5px 15px;
        }

        // Reference style
        .reference-description{
            @include padding(20px 10px 20px 50px);
            margin-top: 20px;
        }

        .media-description{
            padding: 20px 14px 20px 49px;
        }

        .video{
            iframe{
                max-width: 292px;
                 max-height: 178px;
            }
        }

        .work-img img{
            min-height: 242px;
        }

        .featured-box{
            .col-md-6{
                padding: 0;
            }
            .rex-featured-description{
                padding: 0 15px;
            }
        }

        .reference-img{
            margin-right: 15px;
        }


        // blog comments area
        .comments{
            padding-top: 16px;
        }

        .meta{
            text-align: center;
        }

        .section-block.skill-section .skill-content{
            padding: 25px 64px;
            .title{
                text-align: center;
            }

        }

        .rex-portfolio-showcase .rex-modal{
            img{
                width: auto;
            }
            .rex-content{
                padding: 0 25%;
            }
            button.rex-close-modal{
                right: 154px;
            }
        }

        .rex-portfolio-showcase .rex-show{
            background: transparent;
        }

        .three-column{
            margin-bottom: 40px;
        }


    }

        /* Medium Devices, Desktops */
    @media only screen and (min-width : 992px){

        body{
            text-align: left;
        }

        .menu-section ul li{
            text-align: center;
        }

        .menu-section{
            ul li ul li a{
                padding-left: 0;
            }
            ul li ul li ul li a{
                padding-left: 0;
            }
        }

        ul li{
            &:hover{
                .touch-button{
                    transition: 0.5s;
                    opacity: 0;
                }
                ul li{
                    .touch-button{
                        transition: 0.5s;
                        opacity: 1;
                    }
                }
            }
        }

        ul li ul li{
            &:hover{
                .touch-button{
                    transition: 0.5s;
                    opacity: 0;
                }
                ul li{
                    .touch-button{
                        transition: 0.5s;
                        opacity: 1;
                    }
                }
            }
        }

        .header-two div.navigation,
        .header-three div.navigation{
          background: rgba(255, 255, 255, 0.1);
          border-radius: 2px;
        }
        .header-two{
            .nav-down{
                div.navigation,
                .header-three div.navigation{
                  background: transparent;
                }
            }
        }

        .flexnav .touch-button{
            background: transparent;
            width: 30px;
            .navicon{
                font-size: 12px;
            }
        }

        .flexnav .touch-button .navicon{
            top: 1.3em;
        }

        .header-two{
            .flexnav li > ul{
                background: rgba(255, 255, 255, 0.10);
            }
            .flexnav li, .header-three .flexnav li{
                text-align: center;
            }
            .flexnav li ul li a, .header-three .flexnav li ul li a,
            .header-two .header-three .flexnav li ul li a,
            .header-three .header-three .flexnav li ul li a{
                padding-left: 0;
            }
            .flexnav li ul li ul li a,
            .header-three .flexnav li ul li ul li a,
            .header-two .header-three .flexnav li ul li a,
            .header-three .header-three .flexnav li ul li a{
                padding-left: 15px;
                text-align: left;
            }
            .flexnav li ul.open ul.open{
                margin-left: -100%;
            }
        }

        .flexnav li ul li ul li a,
        .flexnav li ul li ul li a,
        .flexnav li ul li a,
        .flexnav li ul li a{
            padding-left: 15px !important;
            text-align: left;
        }

        .flexnav li ul.open ul.open{
            margin-left: -100%;
        }



        .flexnav li ul li > ul{
            margin-left: -100%;
        }


        .flexnav.one-page{
            transform: translateY(0);
        }


        // blog comments area
        .comments{
            .media-left{
                padding-right: 16px;
                margin-right: 7px;
                display: table-cell;
            }
        }

        .rex-featured-img img{
            margin: 0;
        }

        .navigation-menu{
            background: transparent;
        }

        .single-blog-right{
            .select-blog-list{
                float: right;
                padding-left: 15px;
            }
        }
        // header three
        .header-three{
            .hero .hero-inner{
                padding: 12.3% 4.5em;
            }
            .header-three-style{
                text-align: left;
            }
            .sub-title,.hero-btn{
                display: block;
            }
        }
        .section-block{
            @include padding(48px 68px 52px);
        }

        // Header style
         .header{
            text-align: left;
            .header-contact-address{
                text-align: right;
            }
        }

        // nav style

        // filter-btns class
        .rex-filter-btns{
            padding-top: 0;
            text-align: right;
        }

        .rex-featured,
        .rex-featured-img{
            .display-flax{
                margin-bottom: 15px;
                display: flex;
                border: 1px solid #e8e8e8;
            }
        }

        .rex-featured{
            .rex-featured-description > p{
                &:last-child{
                    display: none;
                }
            }
        }

        // .angle section style
        .experience{
            .angle{
                display: block;
                transform: translateX(20px);
            }
        }


        // About section style
        .about-content{
          img{
            padding-bottom: 0;
          }
        }

        // Experience section Style
        .experience-date{
            // width: 120px;
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(21px,-8px);
            h6{
                // padding: 29% 0;
                padding: 33% 0;
            }
        }

        .experience{
            .listing-content{
                &:hover{
                    .list-description{
                        background: $brand-primary;
                        box-shadow: 0 3px 12px 0px rgba(0, 0, 0, 0.38);
                        h6,p{
                            color: $white-base;
                        }
                        span{
                            color: lighten($btn-primary-bg, 30%);
                        }
                    }
                    .experience-date{
                        background: $brand-primary;
                        box-shadow: 3px 0px 7px 0px rgba(0,0,0,0.05);
                        h6{
                            color: $white-base;
                        }
                    }
                }
            }
        }

        .education{
            .listing-content{
                &:hover{
                    background: $brand-primary;
                    box-shadow: 0 3px 12px 0px rgba(0, 0, 0, 0.38);
                    .list-description{
                        border-color: $btn-primary-bg;
                        h6,p{
                            color: $white-base;
                        }
                        span{
                            color: lighten($btn-primary-bg, 30%);
                        }
                    }
                    .list-img{
                        border-color: $btn-primary-bg;
                        border-right-color: darken($btn-primary-bg, 10%);
                    }
                }
            }
        }

        // Education section style
        .listing-content{
            display: flex;
        }

        .experience .list-description{
            // box-shadow: 0.8px 1px 1.8px 0.2px #ddd;
            box-shadow: 0.5px 0.5px 5px 0px #ddd;
            border-width: 0 !important;
        }

        .list-img,
        .list-description{
            @include border-width(1px 1px);
            @include border-style(solid);
            border-color: $border-color;
        }

        .list-img img{
            width: 100%;
            height: auto;
        }

        .list-description{
            padding: 0 2%;
            border-left: transparent;
        }

        // Address
        .address{
            box-shadow: 1px 1px 2px 2px rgba(238, 238, 238, 0.8);
            position: absolute;
            top: 40px;
            left: 40px;
            border-color: transparent;
            ul{
                margin-bottom: 0;
            }
        }

        // Contact-btn
        .contact-btn{
            text-align: right;
        }

        .rex-featured-img-list{
            &:nth-child(2){
                margin: 0 15px;
                margin-bottom: 0px;
            }
        }

        .form .form-group:first-child{
            padding-right: 0;
        }

        .sidebar{
            margin: 0;
        }

        .work-img img{
            max-height: 270px;
        }

        .featured-box{
            .col-md-6{
                padding-left: 0;
            }
        }
        .rex-featured-description{
            padding: 0;
        }

        .rex-portfolio-showcase{
            padding: 0 27px;
            .portfolio-menu{
                display: inline-block;
                float: right;
            }
            .title{
                float: left;
                transform: translateY(13px);
            }
            .rex-modal{
                img{
                    width: 50%;
                }
            }
            .rex-featured{
                padding: 12px 0 0;
            }
        }

        .dom-featured .work-img img{
            width: auto;
        }

        .portfolio-section{
            padding: 48px 54px;
        }


        .rex-show{
            img.col-md-6{
                float: none;
            }
        }

        .rex-portfolio-showcase {
            .rex-show {
                .rex-content {
                    position: absolute;
                    img{
                        margin: 0 auto;
                        padding: 0;
                    }
                }
                figcaption{
                    float: none;
                    margin: 0 auto;
                }
            }
            .rex-modal button.rex-close-modal{
                right: 25%;
            }
            .rex-modal > .rex-content{
                padding: 0;
            }
        }


        .reference-img{
            float: left !important;
        }

        .address{
            padding: 20px;
        }

        .meta{
            text-align: right;
        }

        .comments-section.single-blog-list{
            padding: 22px 84px;
        }

        .section-block.skill-section .skill-content{
            .title{
                text-align: left;
                margin-bottom: 22px;
            }
        }

        .reference-link-title{
            margin-bottom: 13px !important;
        }

        .rex-blog-section{
            margin: 0;
        }

        .map{
            margin: 0;
        }

        .contact-forms{
            padding-top: 0;
        }

        .education .listing-content{
            margin-left: 14px;
        }

        .blog-section .rex-blog-section{
            margin: 30px 0 0 0;
        }

        .blog-title{
            padding: 0;
        }

        .single-blog-list p{
            padding: 25px 0;
        }

        .comments{
            padding-top: 0;
        }

        .single-blog-right{
            .sidebar{
                // text-align: right;
            }
            .description{
                float: right;
            }
        }

        #rex-blog-slider{
            .col-md-4{
                width: 33.33333%;
            }
        }

        .grid-item{
            width: 380px;
        }

        .three-column{
            margin-bottom: 60px;
        }

        .media-left{
            // display: table-cell;
        }
        .rex-widget .media-left{
            padding-right: 12px;
        }

        .menu-section{
            padding: 0 15px;
        }

        .single-blog-right{
            .rex-widget > .media{
                .media-left{
                    display: block;
                }
            }
        }
        .single-blog-right{
            .recent-post > .media{
                .media-left{
                    display: table-cell;
                }
            }
        }
        .single-blog{
            .recent-post > .media{
                .media-left{
                    display: table-cell;
                }
            }
        }
        .education{
            .listing-content{
                border: 0;
                margin: 0 20px 20px;
            }
            .list-img{
                background: transparent;
            }
        }

        #blog{
            .section-block{
                .col-md-12{
                    padding: 0;
                }
            }
        }

    }

        /* Large Devices, Wide Screens */
    @media only screen and (min-width : 1200px){

        .flexnav li ul li > ul{
            margin-left: 100%;
        }

        .title{
            margin-bottom: 30px;
        }

        .select-blog-list{
            // padding-left: 65px;
            // padding-left: 20%;
        }

        .single-blog-right{
            .select-blog-list{
                float: right;
                padding-left: 15px;
                // padding-right: 20%;
            }
        }

        // header three
        .header-three{
            min-height: 750px;
        }

        // Experience section Style
        .experience-date{
            transform: translate(20px, -20px);
        }

        // Education section style
        .list-description{
            padding: 1% 5% !important;
        }

        .experience .list-description{
            padding: 30px 8% !important;
        }

        .education .list-description{
            padding: 2% 5% 1% !important;
        }

        // rex-featured-description style
        .featured-box .rex-featured-description{
            padding: 15px 20px;
        }

        // Address
        .address{
            box-shadow: 1px 1px 2px 2px rgba(238, 238, 238, 0.8);
            position: absolute;
            top: 55px;
            left: 65px;
            border-color: transparent;
        }

        // skill-content
        .section-block.skill-section .skill-content{
            @include padding(50px 64px);
        }

        .rex-portfolio-showcase{
            padding: 0 27px;
            .rex-modal{
              height: 0;
              img{
                width: 50%;
              }
              .rex-content{
                  position: relative;
                  transform: translate(0%, -50%) !important;
                  display: flex;
                  background: #fff;
              }
            }
            .rex-modal button.rex-close-modal{
                right: 0;
            }
        }


        // Widget style
        .rex-widget{
            .description{
                // padding-right: 70px;
            }
        }

        .skillbar{
            margin-bottom: 7px;
            &:last-child{
                margin-bottom: 0;
            }
        }

        .experience {
            .listing-content{
                padding-top: 20px;
            }
        }

        .rex-featured{
            .rex-featured-description > p{
                &:last-child{
                    display: block;
                }
            }
        }

        .address{
            padding: 30px;
        }

        .comments-section.single-blog-list{
            padding: 35px 68px;
        }

        .about-section{
            .list-group-item > .row{
                display: flex;
            }
        }


    }
