/**
 * ---------------------------------------------------------------------------
 * 0?. Footer Styles
 * ---------------------------------------------------------------------------
 */

footer{
	text-align: center;
	p{
		padding: 20px 0;
		margin: 0;
	}
}