/**
 * ---------------------------------------------------------------------------
 * 0?. Navigation
 * ---------------------------------------------------------------------------
 */

/* Flexnav Base Styles */

$nav_text: $white-base;
$top_level: $brand-primary;
$second_level: $brand-primary;
$third_level: $brand-primary;
$fourth_level: $brand-primary;

/*-===== Our styel  =====-*/
.main-menu{
  background: $brand-primary;
  padding-bottom: 60px;
  text-align: center;
}

nav{
  min-height: 55px;
  box-shadow: 0.139px 0.99px 3px 1px rgba(0, 1, 1, 0.118);
}

.one-page{
  .active{
    .navicon{
      color: transparent !important;

      position: relative;
      &:after{
        content: "";
        font-family: FontAwesome;
        width: 12px;
        height: 5px;
        background: #fff;
        position: absolute;
        top: 5px;
        right: 0;
      }
    }
  }
}

.header-two{
  .main-menu{
    background: transparent;
  }
  .menu-button{
    background: transparent;
    text-align: left;
  }
  .flexnav li{
    text-align: left;
  }
  .flexnav li ul li a{
    background: transparent;
  }
}

.nav-tow{
  .flexnav.one-page{
    background: rgba(255, 255, 255, 0.1);
  }
  .menu-button.one-page{
    background: rgba(255, 255, 255, 0.10);
  }

}
  .menu-button{
    .touch-button{
      i{
      position: relative;
        &:before{
          content: "\f0c9";
          font-size: 20px;
        }
      }
    }
    .active .navicon{
      color: #fff !important;
      &:after{
        background: transparent;
      }
    }
  }

.header-two{
  .menu-section ul li{
    background: transparent;
  }
}

.menu-section{
  padding: 0;
}


/*-==== End =====-*/
.flexnav {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  transition: none;
  -webkit-transform-style: preserve-3d; // stop webkit flicker
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  max-height: 0;
  
  &.opacity {
    opacity: 1;
    display: block;
  }
  
  &.flexnav-show {
    max-height: 2000px;
    opacity: 1;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
  }
  &.one-page {
    // position: fixed;
    top: 50px;
    right: 5%;
    max-width: 200px;
    max-width: 100%;
    transform: translateY(50px);
  }
  
  li {
    font-size: 16px;
    position: relative;
    overflow: hidden;
  }
  li a {
    position: relative;
    display: block;
    padding: .96em;
    z-index: 2;
    overflow: hidden;
    color: $nav_text;
    // background: #a6a6a2;
    border-bottom: 1px solid rgba(0,0,0,.15);
  }
  li ul {
    width: 100%;   
    box-shadow: 0.139px 0.99px 3px 0px rgba(0, 1, 1, 0.118);
    li {
      font-size: 100%;
      position: relative;
      overflow: hidden;   
    }
  }
  li ul.flexnav-show {
    
    li {
      overflow: visible;
    }
  } 
  
  li ul li a {
    display: block;
    background: $second_level;
    transition: 0.5s;
    &:hover{
      background: darken($link-color, 15%);
    }
  }
  ul li ul,
  ul li ul li ul{
    box-shadow: 0.139px 0.99px 3px 1px rgba(0, 1, 1, 0.118);
  }
  ul li ul li a {
    background: $brand-primary;
  }
  ul li ul li ul li a {
    background: $brand-primary;
  }
  .touch-button {
    position: absolute;
    z-index: 999;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    display: inline-block;
    // background: #acaca1;
    // background: rgba(0,0,0,.075);
    text-align: center;
      background: rgba(255, 255, 255, 0.1);
    &:hover {
      cursor: pointer;
    }
    .navicon {
      position: relative;
      top: 0.9em;
      color: $white-base;
      font-size: 20px;
    }
  }
}
.menu-button {
  position: relative;
  display: block;
  padding: 1em;
  background: $top_level;
  color: $nav_text;
  cursor: pointer;
  // border-bottom: 1px solid rgba(0,0,0,.2);
  
  &.one-page {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: left;
    // top: 0;
    // right: 5%;
    // padding-right: 45px;
  }
  
  .touch-button {
    background: transparent;
    position: absolute;
    z-index: 999;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    display: inline-block;
    text-align: center;
        
    .navicon {
      font-size: 16px;
      position: relative;
      top: 1em;
      color: $white-base;
    }
  }
}

@media all and (min-width: 800px) {
  body.one-page {
    padding-top: 70px;
  }
  .flexnav {
    overflow: visible;
    &.opacity {
      opacity: 1;
      display: block;
    }
    &.one-page {
      // top: 0;
      max-width: 1080px;
      right: auto;
    }
    li {
      position: relative;
      list-style: none;
      float: left;
      display: block; 
      // background-color: #a6a6a2;
      overflow: visible;
      width: 11%;
    }
    li a {
      // border-left: 1px solid #acaca1;
      border-bottom: none;
    }
    li > ul {
      position: absolute; 
      top: auto;
      left: 0;
      min-width: 150px;
      
      li {
        width: 100%;
      }
    }
    li ul li > ul {
      margin-left: 100%;
      top: 0;
    }
    li ul li:hover > ul,
    li ul li > ul.flexnav-show {
    }   
    li ul li a {
      border-bottom: none;
    }
    li ul.open {
      display: block;
      opacity: 1;
      visibility: visible;
      z-index: 1;
      li {
        overflow: visible;
        max-height: 100px;
      } 
      ul.open {
        margin-left: 100%;
        top: 0;
      }
    }
  }
  .menu-button {
    display: none;
  }
}

.oldie {
  body.one-page {
    padding-top: 70px;
  }
  .flexnav {
    overflow: visible;
    &.one-page {
      top: 0;
      right: auto;
      max-width: 1080px;
    }
    li {
      position: relative;
      list-style: none;
      float: left;
      display: block; 
      background-color: #a6a6a2;  
      width: 20%;
      min-height: 50px;
      overflow: visible;
    }
    li:hover > ul { 
      display: block;
      width: 100%;
      overflow: visible;
      li {
        width: 100%;
        float: none;
      }
    }
    li a {
      border-left: 1px solid #acaca1;
      border-bottom: none;
      overflow: visible;  
    }
    li > ul {
      background:#acaca1;
      position: absolute; 
      top: auto;
      left: 0;
      display: none;
      z-index: 1;
      overflow: visible;
    }
    li ul li ul { 
      top: 0;   
    }
    li ul li a {
      border-bottom: none;
    }
    li ul.open {
      display: block;
      width: 100%;
      overflow: visible;
      li {
        width: 100%;
      }
      ul.open {
        margin-left: 100%;
        top: 0;
          display: block;
        width: 100%;
        overflow: visible;
      }
    }
    ul li:hover ul {
      margin-left: 100%;
      top: 0;
    }
  }
  .menu-button {
    display: none;
  }
  &.ie7 .flexnav li {
    width: 19.9%;
  }
}