

/*============================
*** Blog style
==============================*/

	.blog-section{
		padding-top: 33px;
		.sidebar{
			transform: translateY(30px);
		}
		.rex-blog-section{
			margin:15px 0;
		}
	}

	.rex-blog-section{
		padding: 0;
		border-radius: 0;
		border-bottom: 0;
		margin-top: 30px;
		box-shadow: none;
		border-color: #e8e8e8;
		transition: 0.5s;
		img{
		    width: 100%;
		}
		&:hover{
			transform: translateY(-5px);
			box-shadow: 0 3px 12px 0px rgba(0, 0, 0, 0.38);
		}
	}

	.rex-caption{
		padding: 30px;
		p{
			margin-bottom: 0;
		}
		h6{
			margin-top: 0;
			font-size: 17px;
			transition: 0.5s;
		}
		a{
			margin-top: 5px;
			display: inline-block;
			&:hover > h6{
				color: $brand-primary;
			}
		}
	}

	.white-panel{
		.thumbnail{
			margin: 0;
		}
	}

	.select-blog-list > img{
		width: 100%;
	}

	// single-blog-right

	.single-blog-right{
		.rex-widget{
			.description{
				padding-right: 15px;
			}
			p > br{
				display: none;
			}
		}
	}

	#rex-blog-slider{
		.col-md-4{
			width: 100%;
		}
	}


	.three-column{
		margin-bottom: 20px;
	}
