

	.address{
		padding: 30px;
		@include background(url($contact-bar));
		background-color: #fff;
		background-repeat: repeat-y;
		@include border-width(0px 1px 1px 0px);
		border-color:$rex-border-lighter;
		border-style: solid;
		&:before{
			content: "";
			position: absolute;
			top: 25px;
			right: 15px;
			width: 133px;
			height: 55px;
			@include background(url($contact-bg));
		}
		h6, p{
			margin: 0;
		}
		ul{
			li{
				border: 0;
				border-radius: 0;
				padding: 0;
				background: transparent;
			    margin-bottom: 15px;
				&:last-child{
					border-radius: 0;
				}
			}
		}
		a{
			color: $rex-gray-dark;
			transition: 0.5s;
			&:hover{
				color: $brand-primary;
			}
		}
	}

	.contact-btn{
		text-align: center;
		a{
			margin: 7px;
			@include padding(17px 55px);
		}
	}