.hero {  
  $base-border-radius: 3px !default;
  $action-color: #477DCA !default;
  $large-screen: em(860) !default;
  $hero-background-top: #7F99BE; 
  $hero-background-bottom: #20392B;
  $hero-color: white;
  $gradient-angle: 10deg;

  @include background(url($hero-image));
  background-color: $brand-primary;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 0.2em;

  .hero-logo img {
    height: auto;
    margin-bottom: 1em;
  }

  .hero-btn > a{
    margin: 10px auto;
  }

  .hero-inner {
    @include clearfix;
    margin: auto;
    padding: 1.5em;
    text-align: center;

    .media-link{
      padding: 10px 0;
    }
    .hero-btn{
      padding-top: 15px;
    }
    .hero-copy {
      text-align: center;
      h1,h2,h3,h4,h5,h6 {
        color: $hero-color;
        line-height: 1;
      }

      p {
        color: $hero-color;
      }
    }
  }
}

.about-section{
  background: $white-base;
  @extend .box-border;
  @include padding(15px);
  h4{
    padding-bottom: 6px;
      // text-transform: uppercase;
  }
  h6{
    margin: 0;
  }
  .list-group > .list-group-item{
    border: 0;
    padding: 0
  }

  .list-group{
    margin-bottom: 0;
  }
}
