/**
 * ---------------------------------------------------------------------------
 * 0?. Header Styles
 * ---------------------------------------------------------------------------
 */

 .header{
	padding: 20px 0;
	text-align: center;
	background: ($brand-primary - 12px);
 }
 .logo{
 	padding: 15px;
 }
 .header-contact-address{
 	// text-align: right;
 	a{
 		color: $white-base;
 		&:last-child{
 			font-size: 18px;
 			font-weight: 300;
 		}
 	}
 }


.header-two{
	padding-top: 30px;
	@include background(url($folding-bg));
	background-color: $brand-primary;
	background-position: top;
	background-repeat: no-repeat;
	background-size: cover;
	.hero{
		background: transparent;
	}
	div.navigation{
		box-shadow: none;
		// background: rgba(255, 255, 255, 0.10);
	}
}

.header-three{
	@extend .header-two;
	.top-title{
		color: $white-base;
		// font-size: 26px;
		font-size: 20px;
	    text-align: right;
	    padding: 0;
	}
	.author-name{
		// font-size: 60px;
		// font-size: 100%;
		margin-top: 0;
	}
	.sub-title,.hero-btn{
		display: none;
	}
	.sub-title{
		font-weight: 300;
		font-size: 20px;
	}
	.header-three-style{
		// text-align: left;
	    padding: 0;
	}
	.hero-btn{
		transform: translateX(-15px);
	}
}