




.section-block.skill-section{
	padding: 0;
	h6{
		margin-bottom: 0;
	}
	img{
		width: 100%;
	}
	.skill-content{
	    padding: 25px 20px 7px;
	}
}

.skill-content{
	@include padding(20px);
	text-align: left;
	// .title{
	// 	text-align: center;
	// }
}



.skillbar {
	position:relative;
	display:block;
	margin-bottom:15px;
	width:100%;
	// background:#eee;
	// height:35px;
	border-radius:3px;
	-moz-border-radius:3px;
	-webkit-border-radius:3px;
	-webkit-transition:0.4s linear;
	-moz-transition:0.4s linear;
	-ms-transition:0.4s linear;
	-o-transition:0.4s linear;
	transition:0.4s linear;
	-webkit-transition-property:width, background-color;
	-moz-transition-property:width, background-color;
	-ms-transition-property:width, background-color;
	-o-transition-property:width, background-color;
	transition-property:width, background-color;
}

.skillbar-title {
	position:absolute;
	top:0;
	left:0;
width:110px;
	font-weight:bold;
	font-size:13px;
	color:#ffffff;
	background:$brand-primary;
	-webkit-border-top-left-radius:3px;
	-webkit-border-bottom-left-radius:4px;
	-moz-border-radius-topleft:3px;
	-moz-border-radius-bottomleft:3px;
	border-top-left-radius:3px;
	border-bottom-left-radius:3px;
}

.skillbar-title span {
	display:block;
	// background:rgba(0, 0, 0, 0.1);
	padding:0 20px;
	height:35px;
	line-height:35px;
	-webkit-border-top-left-radius:3px;
	-webkit-border-bottom-left-radius:3px;
	-moz-border-radius-topleft:3px;
	-moz-border-radius-bottomleft:3px;
	border-top-left-radius:3px;
	border-bottom-left-radius:3px;
}

.skillbar-bar {
	display: inline-block;
	height:10px;
	width:0px;
	position: relative;
	background:$brand-primary;
    // transform: translateY(-5px);
	border-radius:10px;
	-moz-border-radius:10px;
	-webkit-border-radius:10px;
}

.skill-bar-percent {
	// display: inline-block;
	position: absolute;
	right: 0;
	top: -30px;
	background: transparent;
	color: $brand-primary;
	font-weight: 700;
	border-radius: 100%;
	width: 38px;
	height: 38px;
	text-align: center;
    padding: 7px 5px;
    // transform: translate(-23px, -5px);
    // box-shadow: 0px 0px 0px 1px #d0d0d0;
}