
/**
 * ---------------------------------------------------------------------------
 * 0?. Reference Section Styles
 * ---------------------------------------------------------------------------
 */

 	.reference{
 		padding: 15px;
 	}
 	.reference >.active-section{
 		.section-block{
		    padding: 22px;
 		}
 	}
	.reference-img{
		float: none !important;
		img{
			// margin-right: 15px;
		}
	}
	.reference-title{
	    padding: 5.5% 0;
		h6{
			margin-bottom: 0;
			line-height: 1;
			font-size: 17px;
		}
		span{
			color: #999;
			font-size: 12px;
		}
	}

	.reference-description{
		@extend .border;
		margin-top: 10px;
		@include padding(20px);
		@include background(url($quotation));
	    background-repeat: no-repeat;
	    background-position: 10px 20px;
	}