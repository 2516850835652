/**
 * ---------------------------------------------------------------------------
 * 0?. Base Styles.
 * ---------------------------------------------------------------------------
 */

 /* Font family */

@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900,900italic);

body{
	background: #e5e5e5;
	text-align: center;
}

body{
	line-height: $font-size-base-line-height;
	font-weight: $font-size-base-weight;
}

h1,h2,h3,h4,h5,h6{
	color: $rex-heading-color;
}

h2{
	font-weight: $headings-font-weight-h2;
	line-height: $headings-line-height-h2;
}

h3{
	font-weight: $headings-font-weight-h3;
	line-height: $headings-line-height-h3;
}

h4{
	font-weight: $headings-font-weight-h4;
	line-height: $headings-line-height-h4;
}

h5{
	font-weight: $headings-font-weight-h5;
	line-height: $headings-line-height-h5;
}

h6{
	font-weight: $headings-font-weight-h6;
	line-height: $headings-line-height-h6;
}

span{
	display: inline-block;
	font-weight: $font-size-base-weight + 100;
}

ul{
	list-style: none;
	margin: 0;
	padding: 0;
}

// section{
// 	padding: 100px 0;
// }

input,textarea{
	box-shadow: none !important;
    resize: none;	
    padding: 18px 26px !important;
}

input{
	min-height: 50px;
	&:focus{
		border-color: $brand-primary !important;
		box-shadow: none !important;
		outline: none !important;
	}
}

textarea{
	min-height: 180px;
}

a:hover{
	text-decoration: none;
}
a:focus{
	text-decoration: none;
	outline: none;
}

.btn-default{
	&:hover{
		border-color: transparent;
	}
	&:active,&:focus{
		background: transparent;
		box-shadow: none;
		border-color: $border-color;
	}
}




/* help
----------------------------------*/
@import "help";

/* help
----------------------------------*/
@import "mixin";

 
