/*====================================
=            Help section            =
====================================*/

/*----------------
// active color and background
------------------*/

.active-section{
	padding-top: 60px !important;
	margin-top: -60px !important;
}

.main{
	// z-index: 1;
}

.main-menu{
	z-index: 10;
	position: relative;
}

.active-color{
	color: $brand-primary;
}

.active-background{
	background: $brand-primary;
}

/*----------------
// ** padding style
------------------*/

.padding-none{
	padding: 0;
}

.padding-left{
	padding-left: 0;
}
.padding-right{
	padding-right: 0;
}

/*----------------
// ** Displa style
------------------*/

.display{
	display: block;
}

.display-inline{
	display: inline-block;
}

.display-flax{
    display: flex;
}

//select-blog-list
.select-blog-list{
	// padding-left: 0;
}

// Border-radius

.border-radius{
	border-radius: 100%;
}

// Single Blog
.single-blog-list{
	background: $white-base;
	@extend .box-border;
	@include padding(10px);
}


/*----------------
// before and after
------------------*/

.before-after{
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	@include transition(all 2s ease-in-out);
}


/*----------------
// button class
------------------*/
.square{
	position: relative;
	&:before{
		@extend .before-after;
		width: 100%;
		height: 2px;
		top: 12%;
		right: -100%;
		background: $brand-primary;
		@include transition(0.8s $ease-in-out-back);
	}
	&:after{
		@extend .before-after;
		width: 2px;
		height: 100%;
		top: -100%;
		right: 10%;
		background: $brand-primary;
		@include transition(0.8s $ease-in-out-back);
	}
}


/*----------------
// Span class
------------------*/
.border-dashed{
	width: 100%;
	height: 1px;
    margin: 14px 0;
    overflow: hidden;
	@extend .display;
    background: url('../img/dashed-img.png') repeat-x;
}

/*----------------
// Border class
------------------*/

.border{
	@include border-width(1px 1px);
	@include border-style(solid);
	border-color: $border-color;
}

.box-border{
	border:solid $gray-darker;
	@include border-width(0px 1px 2px 1px);
	@include border-color(null $gray-lighter $gray-darker $gray-lighter);
}


/*----------------
// Title class
------------------*/

.title{
	margin-top: 0;
	margin-bottom: 15px;
}

/*----------------
// Social Media link class
------------------*/
.media-link{
	a{
		margin: 0 6px;
		color: $white-base;
		@extend .display-inline;
		@include transition(0.8s $ease-in-out-back);
		&:hover{
			color: $brand-primary;
		}
	}
	i{
		font-size: 24px;
	}
}

/*----------------
// Section Block class
------------------*/
.section-block{
	background: $white-base;
	@include padding(30px);
	@extend .box-border;
}

/*----------------
// Angle class
------------------*/
.angle{
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 20px 20px 0 0;
	border-color: $angle-color transparent transparent transparent;
}


/*----------------
// filter-btns class
------------------*/
.rex-filter-btns{
	.btn{
		padding: 10px 3%;
		border-radius: 0;
		background-color: rgb(255, 255, 255);
		box-shadow: 0.139px 0.99px 3px 0px rgba(0, 1, 1, 0.2);
		border-color: transparent;
		transition: 0.5s;
		display: block;
		&:hover{
			box-shadow: none;
			background: transparent;
			border: 1px solid $border-color;
		}
	}
	.active{
		box-shadow: none;
		background: transparent;
		border: 1px solid $border-color;
	}
}


/*--------------------
// Override Bootstrap
// img-thumbnail class
----------------------*/

.img-thumbnail{
	padding: 8px;
	border-radius: 0;
}

.thumbnail{
	box-shadow: 0px 0px 1px 0px #c6c7c7;
}


/*----------------
// Pagination class
------------------*/
.owl-pagination{
    padding: 5px 0;
	.owl-page{
		width: 10px;
		height: 10px;
		background: $gray-lighter;
		display: inline-block;
		margin: 0 5px;
		border-radius: 100%;
		transition: 0.5s;
	}
    .active{
    	background: $brand-primary;
    	transition: 0.5s;
    }
}

/*----------------
// Pagination Angle class
------------------*/
.owl-controls{
	text-align: center;
    line-height: 1;
}
.owl-buttons{
	div{
	    display: inline-block;
	    padding: 5px 15px;
	    font-size: 18px;
	    margin: 0 5px;
    }
}



/*----------------
// post-meta class
------------------*/
.post-meta{
	text-align: right;
	@include border-width(1px 0px 1px 0px);
	border-color:#e8e8e8;
	border-style: solid;
	a{
		padding: 10px 15px;
		display: inline-block;
		border-left: 1px solid #e8e8e8;
		color: $input-color-placeholder;
		font-size: 12px;
		@include transition(all 0.5s ease-in-out);
		&:first-child{
			float: left;
			border-left: 0;
			border-right: 1px solid #e8e8e8;
			i{
				margin-right: 0;
			}
		}
		&:hover{
			color: $brand-primary;
		}
	}
	i{
		margin-right: 4px;
	}
}


// Map
.map{
	@include border-width(1px 1px);
	border-color:$rex-border-lighter;
	border-style: solid;
	margin-top: 20px;
	img{
		width: 100%;
	}
}

// Contact Forms
.contact-forms{
	// padding: 25px 0;
	@include padding(25px 0);

}
.form-control{
	&:focus{
		box-shadow: 0 3px 5px 0px rgba(0, 0, 0, 0.15) !important;
	}
}

// skillbar Style
.skillbar{
	position: relative;
	&:before{
		content: "";
		position: absolute;
		left: 0;
		top: 7px;
		width: 100%;
		height: 10px;
		background: #e5e5e5;
		border-radius: 10px;
	}
}

.background{
	display: inline-block;
	background: $brand-primary;
	width: 100%;
	height: 190px;
	position: absolute;
	left: 0;
}


// font-weight-light

.font-weight-light{
	font-weight: 400;
}



#pinBoot {
  position: relative;
  max-width: 100%;
  width: 100%;
}
.white-panel {
  position: absolute;
  // background: white;
  padding: 10px;
	img {
	  width: 100%;
	  max-width: 100%;
	  height: auto;
	}
}

// hr section
.hr{
	margin: 50px auto 0;
	color: #999;
}

// transparent
.transparent{
	border-color: transparent;
}

// pagination section styel
.pagination{
	margin: 0;
	ul{
		margin: 0;
	}
	ul>li.pages-active{
		a{
			background: $white-base !important;
		}
	}
	ul>li>a{
		box-shadow: none;
		background: #F1F1F1;
		color: $rex-heading-color;
		// border: 0;
		&:hover{
			background: transparent;
		}
		span{
			margin-right: 5px;
		}
	}
	ul > li > a:hover{
	    background: #fff;
	    transition: 0.5s;
	}
	.previous,
	.next{
		background: #FFFFFF;
	}

	.active{
		background: #fff;
		color: $brand-primary;
	}
}

// pages btn
.pages-btn{
	.btn-default{
		box-shadow: 0px 0px 1px 0px #c6c7c7;
		border-color: transparent;
		color: #999;
		transition: 0.5s;
		background: $white-base;
		margin: 2px;
		&:hover{
			color: $brand-primary;
			background: $white-base;
		    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
		}
	}
}

.pages-btn{
	padding-left: 10px;
	i{
		margin: 0 10px;
	}
}

.blog-title{
	h3{
	    margin-top: 7px;
	    margin-bottom: 0;
	}
	span{
		color: $brand-primary;
	}
}


a#scrollUp{
    width: 27px;
    height: 27px;
    bottom: 20px;
    right: 50px;
    text-indent: -999999px;
    position: relative;
	background: url("../img/arow-up.png") no-repeat;
}


.is-sticky{
	position: absolute;
    left: 0;
    width: 100%;
    div.navigation{
    	width: 100% !important;
	    box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.38) !important;
    }
}

.header-two{
	.is-sticky > .navigation{
		background: $brand-primary;
	}
}

.video{
	iframe{
		width: 100%;
	}
}

.menu-open{
	text-indent: -9999px;
	position: relative;
	&:before{
		content: "\f00d";
		position: absolute;
		left: 50%;
		top: 50%;
		color: $white-base;
		text-indent: 0px;
		font-size: 20px;
		font-family: FontAwesome;
		transform: translate(-50%,-50%);
	}
}

.menu-section{
	ul li{
		background: $brand-primary;
		text-align: left;
	}
	ul li ul li a{
		padding-left: 30px;
	}
	ul li ul li ul li a{
		padding-left: 50px;
	}
}

.header-two {
	.flexnav li ul li a, 
	.header-three .flexnav li ul li a{
		padding-left: 30px;
	}
	.flexnav li ul li ul li a, 
	.header-three .flexnav li ul li a{
		padding-left: 50px;
	}
}


/* Animated SVG Loader Style
   ========================= */
div.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  background-color: #fff;
  z-index: 9999999999999;
}
div.loading img {
  position: fixed;
  left: 50%;
  top: 50%;
  // transform: translateY(-50%);
}

/* SVG Backup for Older Browser */
.no-svg div.loading {
  background-image: url(../images/AjaxLoader.gif) no-repeat;
}

.about-content{
  img{
    padding-bottom: 25px;
  }
}

.pagination{
	nav{
		box-shadow: none;
	}
}

.grid-item {
	// width: 380px;
	width: 100%;
	margin: 0 auto;
	float: left;
	padding: 0 7px;
}

div#rex-sticky{
	// transition: 0.5s;
}



/*=====  End of Help section  ======*/