

/*============================
*** Sidebar style
==============================*/

	.sidebar{
	    margin-top: 20px;
		background: $white-base;
		@include padding(42px null 45px 25px);
		.title{
			margin-bottom: 24px;
		}
	}

	// Widget style
	.rex-widget{
		margin-bottom: 45px;
		&:last-child{
			margin-bottom: 0;
		}
		.description{
		    // padding-right: 70px;
		    padding-right: 15px;
		}
		.description p{
		    padding: 16px 0;
		    margin-bottom: 5px;
		}
		.media-left{
			padding-right: 0;			
		}
		.media-left > a{
			img{
				width: 80px;
				display: inline-block;
			}
		}
	}

	// Media Object style
	.media-left{
		margin-right: 7px;
		display: block;
	}
	.media-body{
		padding-top: 3%;
		h6{
			margin-bottom: 0;
		}
		a>h6{
			transition: 0.2s;
			&:hover{
				color: $brand-primary;
			}
		}
	}

	.rex-archive{
		a{
			color: $rex-gray-dark;
			transition: 0.2s;
			&:hover{
				color: $brand-primary;
			}
		}
		i{
			margin-right: 10px;
		}
		ul>li{
		    margin-bottom: 11px;
		    &:last-child{
		    	margin-bottom: 0;
		    }
		}
	}

	.sub-title{
		font-size: 14px;
		line-height: 1;
		transform: translateY(-4px);
		color: #999;
		font-weight: 400;
	}

	.recent-post{
		.media-body{
			padding-top: 2%;
		}
	}