


/*============================
*** Single-Blog style
==============================*/

	.single-blog{
		padding-top: 50px;
	}

	// blog box listing area
	.single-blog-list{
		p{
			@include padding(25px);
			margin: 0;
			&:last-child{
				padding-bottom: 10px;
			}
		}
	}

	.blog-title{
	    padding: 0 20px;
	}

	// blog description area
	.single-blog-description{
		@include padding(35px null 25px null);
	}

	// blog quote area
	.quote{
		hr{
			margin: 0;
		}
		h6{
			padding: 13px 30px;
		}
	}

	// blog comments area
	.comments{
	    padding-top: 16px;
		.media-left{
		    // padding-right: 16px;
		    padding-right: 0;
		    margin-right: 0;
		}
		.media-object{
		    display: inline-block;
		}
	}

	// blog meta
	.meta{
		// text-align: right;
		text-align: center;
		p,.date p,.reply p{
			padding: 0;
		}
		i{
			color: $brand-primary;
		}
		.date,
		.reply{
			display: inline-block;
		}
		.date{
			padding-right: 20px;
		}
	}

	// blog description
	.media-description{
		margin-top: 4px;
		@extend .border;
		@include padding(20px);
		@include background(url($quotation));
	    background-repeat: no-repeat;
	    background-position: 10px 20px;
	    p{
	    	padding: 0;
	    }
	}

	// blog comments form
	.form{
		textarea{
			margin-bottom: 35px;
		}
		.col-md-6,
		.col-md-12{
			padding-left: 15px;
		}
		.form-group{
			input{
				min-height: 50px;
			}
			&:first-child{
				// padding-right: 0px;
			}
		}
	}

	.comments-section.single-blog-list{
	    padding: 22px;
	}