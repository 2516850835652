/*------------------------------------------------------------------

 *
 * Author      : Khorshed Alam (thekhorshed)
 * Project     : KA Starter
 * Version     : 1.0.0
 * Description : Just a simple starter template to jump start your development.
 * Primary use : Starter Template
 * Last update : YYYY/MM/DD
 *

 * -----------------------------------------------------------------*
 *                         TABLE OF CONTENTS                        *
 * -----------------------------------------------------------------*
 *
 * 01. Base Styles
 * 02. Header
 * 03. Navigation
 * 04. Footer Styles
 * 05. Responsive Styles
 *

 * -----------------------------------------------------------------*
 *                        Color Codes                               *
 * -----------------------------------------------------------------*

 * $theme-color         : #fc854b;

 * $text-color          : #787878;
 * $text-color-dark     : #444444;
 * $bg-color-light      : #FFFFFF;
 * $bg-color-dark       : #f1f1f1;

-------------------------------------------------------------------*/





/* Plagin
----------------------------------*/
//plagin
	@import "plugin/plugin";

/*=====End*/

/* Base
----------------------------------*/
	@import "base/base";

	/* Header section style
	-------------------------------- */
	@import "layout/header";

	/* Navigation section style
	---------------------------------*/
	@import "layout/nav";

	/* Footer section style
	-------------------------*/
	@import "layout/footer";

	/* Base
	----------------------------------*/
	@import "base/button";
	
/*=====End*/

/* Layout
----------------------------------*/

	/* About section style
	-------------------------*/
	@import "layout/about";

	/* Skill section style
	-------------------------*/
	@import "layout/skill";

	/* Experience section style
	-------------------------*/
	@import "layout/experience";

	/* Education section style
	-------------------------*/
	@import "layout/education";

	/* Work section style
	-------------------------*/
	@import "layout/work";

	/* Reference section style
	-------------------------*/
	@import "layout/reference";

	/* Blog section style
	-------------------------*/
	@import "layout/blog";

	/* contact section style
	-------------------------*/
	@import "layout/contact";

	/* sidebar section style
	-------------------------*/
	@import "layout/sidebar";


	/* sidebar section style
	-------------------------*/
	@import "layout/single-blog";

/*=====End*/


/* Responsive section style
-------------------------*/
@import "layout/responsive";
